<template>
  <div>
    <nut-skeleton>
      <row padding="15px 10px 0" v-for="(item, index) in 7" :key="index">
        <skeleton-square width="80px" height="80px"></skeleton-square>
        <column padding="0 0 0 10px" height="100px">
          <skeleton-square width="200px" margin="0 0 10px 0"></skeleton-square>
          <skeleton-square width="100px" margin="0 0 10px 0"></skeleton-square>
          <skeleton-square color="#ffffff"></skeleton-square>
          <skeleton-square width="60px"></skeleton-square>
        </column>
        <column padding="0 0 0 60px">
          <skeleton-circle
            diameter="30px"
            margin="40px 0 0 0"
          ></skeleton-circle>
        </column>
      </row>
    </nut-skeleton>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TokenService from "@/services/token.service";
import { AuthMixin } from "@/mixins/auth";

export default {
  name: "Home",

  mixins: [AuthMixin],

  data() {
    return {
      loading: false
    };
  },

  watch: {
    "$route.query.state"(o) {
      if (o !== "null" && !isNaN(o)) {
        this.setOauthCode(o);
      }
    }
  },

  created() {
    if (!this.isLoggedIn()) {
      // Only for QrCode
      const vidId = this.$route.query.state;
      this.setOauthCode(vidId);
    } else {
      this.$router.push({ name: "List" });
    }
  },

  methods: {
    setOauthCode(id = null) {
      if (this.getQueryString("code")) {
        this.loading = true;
        TokenService.setOAuthCode(this.getQueryString("code"));

        this.login({
          code: this.getQueryString("code")
        })
          .then(res => {
            console.log(res);
            this.setUserInfo(res);
            if (id) {
              this.$router.push({
                path: `/video/${id}`
              });
            } else {
              this.$router.push({ name: "List" });
            }
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      }
    },

    getUrlParam(paramName) {
      const params = window.location.search;

      if (params.indexOf(paramName) > -1) {
        let paramValue = "";

        paramValue = params.substring(params.indexOf(paramName), params.length);

        if (paramValue.indexOf("&") > -1) {
          paramValue = paramValue.substring(0, paramValue.indexOf("&"));

          paramValue = paramValue.replace(paramName + "=", "");

          return paramName;
        }
      }

      return "";
    },
    // setOauthCode() {
    //   if (this.getQueryString("code")) {
    //     this.loading = true;
    //     TokenService.setOAuthCode(this.getQueryString("code"));
    //
    //     this.login({ code: this.getQueryString("code") })
    //       .then(res => {
    //         this.setUserInfo(res.data);
    //         this.loading = false;
    //       })
    //       .catch(() => {
    //         this.loading = false;
    //       });
    //   }
    // },

    ...mapActions("common", {
      login: "oauthLogin"
    })
  }
};
</script>

<style scoped></style>
